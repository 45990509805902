import { FC } from "react";
import { Button } from "../../shared/button/Button";
import { LessorAvatar } from "../../shared/lessor/LessorAvatar";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import { Text } from "../../shared/Text/Text";
import styles from "./ui/index.module.css";
import { ModalProps } from "../auth/login/model/types";
import closeIcon from "../../assets/icons/close.svg";

interface Props extends ModalProps {
  socialNetworks: {
    Instagram: string | null;
    LinkedIn: string | null;
    WhatsApp: string | null;
    Telegram: string | null;
  };
}

const ContactsPopUp: FC<Props> = ({ socialNetworks }) => {
  console.log(socialNetworks);
  const handleButtonClick = (value: string) => {
    if (value.startsWith("http")) {
      window.open(value, "_blank");
    } else {
      navigator.clipboard.writeText(value);
    }
  };

  const renderButton = (name: string, value: string | null) => {
    if (!value) return null;
    return (
      <Button
        $icon
        $iconColor
        $iconBgColor="rgba(40, 40, 40, 1)"
        className={styles.social_btn}
        onClick={() => handleButtonClick(value)}
      >
        {name}
      </Button>
    );
  };

  return (
    <div className={styles.header_container}>
      <div className={styles.close_icon}>
        <img src={closeIcon} alt="close icon" />
      </div>
      <div className={styles.header}>
        <LessorAvatar
          className={styles.header_img}
          src={
            "https://i.pinimg.com/564x/25/ee/de/25eedef494e9b4ce02b14990c9b5db2d.jpg"
          }
        />
        <div>
          <SecondTitle>Hosted by Maria</SecondTitle>
          <Text fw="600">JOB</Text>
          <Text>
            I live and work in Madrid. Like to travel, my hobby is dancing
          </Text>
        </div>
      </div>
      <div className={styles.container_links}>
        <div>
          <SecondTitle mb="10" className={styles.links_btn} color="white">
            Social network
          </SecondTitle>
          <div className={styles.container_buttons}>
            {renderButton("Instagram", socialNetworks.Instagram)}
            {renderButton("LinkedIn", socialNetworks.LinkedIn)}
          </div>
        </div>

        <div>
          <SecondTitle className={styles.links_btn} color="white">
            Book an apartment
          </SecondTitle>
          <Text color="white" fw="400">
            Contact Maria in any way convenient for you. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit.
          </Text>
          <Text color="white" fw="500" mb="20px">
            E-mail: username@gmail.com
          </Text>
          <div className={styles.container_buttons}>
            {renderButton("WhatsApp", socialNetworks.WhatsApp)}
            {renderButton("Telegram", socialNetworks.Telegram)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsPopUp;
