import React, { Dispatch, FC, SetStateAction, ChangeEvent } from "react";
import Uploader from "../uploader/Uploader";
import { EditailbleDataType } from "../../pages/editAdvert/model/types";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import { Input } from "../../shared/input/Input";
import DatePicker from "react-datepicker";

import clsx from "./ui/index.module.css";
import { TextArea } from "../../shared/textArea/TextArea";
import { Text } from "../../shared/Text/Text";
import Rooms from "../../shared/rooms/Rooms";

interface Props {
  onImageChanger?: (data: File[]) => void;
  handlegetRooms: (data: number) => void;
  formatData: EditailbleDataType;
  setFormatData: Dispatch<SetStateAction<EditailbleDataType>>;
}

export const EditAdvertEntities: FC<Props> = ({
  onImageChanger,
  handlegetRooms,
  formatData,
  setFormatData,
}) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormatData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className={clsx.wrapper}>
      <div>
        <SecondTitle style={{ fontSize: "50px", fontWeight: "500" }}>
          Edit an advert
        </SecondTitle>
      </div>
      <div>
        <SecondTitle>Photos of the apartment</SecondTitle>
        <Text>Upload no more than 8 photos of your apartment.</Text>
        <Uploader
          images={formatData?.used_listing_pictures}
          onImageChanger={onImageChanger}
        />
      </div>
      <div className={clsx.location}>
        <SecondTitle>Location</SecondTitle>
        <Input
          name="country"
          value={formatData?.country}
          onChange={handleChange}
          className={clsx.input}
        />
        <Input
          name="city"
          value={formatData?.city}
          onChange={handleChange}
          className={clsx.input}
        />
        <Input
          name="district"
          value={formatData?.district}
          onChange={handleChange}
          className={clsx.input}
        />
      </div>
      <div className={clsx.rooms}>
        <SecondTitle>Number of rooms</SecondTitle>
        <Rooms handlegetRooms={handlegetRooms} value={+formatData?.room} />
      </div>
      <div className={clsx.time}>
        <SecondTitle>Departure date</SecondTitle>
        <div>
          <DatePicker
            selected={
              formatData?.date_from ? new Date(formatData.date_from) : null
            }
            onChange={(date) =>
              setFormatData((prevData) => ({
                ...prevData,
                date_from: date?.toISOString() || "",
              }))
            }
            placeholderText="Select a date"
            className={clsx.date_picker}
          />
        </div>
      </div>
      <div className={clsx.time}>
        <SecondTitle>Return to the apartment</SecondTitle>
        <div>
          <DatePicker
            selected={formatData?.date_to ? new Date(formatData.date_to) : null}
            onChange={(date) =>
              setFormatData((prevData) => ({
                ...prevData,
                date_to: date?.toISOString() || "",
              }))
            }
            placeholderText="Select a date"
            className={clsx.date_picker}
          />
        </div>
      </div>
      <div className={clsx.description}>
        <SecondTitle>Description of the apartment</SecondTitle>
        <TextArea
          name="description"
          value={formatData.description}
          onChange={handleChange}
          className={clsx.text_area}
        />
      </div>
      <div className={clsx.cost}>
        <SecondTitle>Cost</SecondTitle>
        <Text>
          For comfortable use of the community for all participants, the rent
          should be indicated at the cost of a long-term lease. Learn more
        </Text>
        <Input
          name="price"
          value={formatData.price}
          onChange={handleChange}
          className={clsx.input}
        />
      </div>
      <div className={clsx.comment}>
        <SecondTitle>Comment</SecondTitle>
        <Text>
          If you have any suggestions or comments about the ad, please add them.
        </Text>
        <TextArea
          name="comments"
          value={formatData.comments}
          onChange={handleChange}
          className={clsx.text_area}
        />
      </div>
    </div>
  );
};
