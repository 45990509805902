import { Button } from "../../../shared/button/Button";
import { Logo } from "../../../shared/logo/Logo";
import userIcon from "../../../assets/icons/user-icon.svg";
import clsx from "./ui/index.module.css";
import { Linked } from "../../../shared/Linked/Linked";
import { useNavigate } from "react-router-dom";

import useAuth from "../../../app/helpers/hooks/UseAuth";
import { useModal } from "../../../app/helpers/hooks/useModal";
import Login from "../../auth/login/Login";
import { useEffect, useState } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const isAuth = useAuth();
  const { isOpen, openModal, closeModal, ModalComponent } = useModal();

  const [burgerOpen, setBurgerOpen] = useState(false);

  const handleClick = () => {
    setBurgerOpen(!burgerOpen);
  };

  useEffect(() => {
    if (burgerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [burgerOpen]);

  return (
    <>
      <nav className={`${clsx.nav} container`}>
        {isAuth ? (
          <>
            <div>
              <Logo onClick={() => navigate("/")}>
                <span>flat</span>
                <span>sharing</span>
              </Logo>
              <ul id={clsx.auth_nav}>
                <Linked onClick={() => navigate("/browse")} $size="18px">
                  Browse apartments
                </Linked>
                <Linked $size="18px" onClick={() => navigate("/how_it_works")}>
                  How it works
                </Linked>
                <Linked $size="18px" onClick={() => navigate("/blog")}>
                  Blog
                </Linked>
              </ul>
            </div>
            <div id={clsx.auth_nav}>
              <Button onClick={() => navigate("/account/myads")} $fz="18px">
                <img
                  src={userIcon}
                  alt="user icon"
                  style={{ marginRight: "10px" }}
                />
                My account
              </Button>
              <Button
                onClick={() => navigate("/add_apartmants")}
                $fz="18px"
                $border
              >
                Add an advert
              </Button>
            </div>
            <a
              id="myButton"
              href="#"
              onClick={handleClick}
              className={`${burgerOpen ? "open" : ""} ${
                clsx.burger_menu_2
              } hamburger-icon cross-animation`}
            >
              <span></span>
            </a>
          </>
        ) : (
          <>
            <div>
              <Logo onClick={() => navigate("/")}>
                <span>flat</span>
                <span>sharing</span>
              </Logo>
            </div>
            <ul>
              <Linked onClick={openModal} $size="18px">
                Browse apartments
              </Linked>
              <Linked $size="18px" onClick={() => navigate("/how_it_works")}>
                How it works
              </Linked>
              <Linked $size="18px" onClick={() => navigate("/blog")}>
                Blog
              </Linked>
            </ul>
            <div className={clsx.buttons}>
              <Button onClick={openModal}>Sign In</Button>
              <Button onClick={() => navigate("/registration")} $border>
                Join Now
              </Button>
            </div>
            <a
              id="myButton"
              href="#"
              onClick={handleClick}
              className={`${burgerOpen ? "open" : ""} ${
                clsx.burger_menu
              } hamburger-icon cross-animation`}
            >
              <span></span>
            </a>
          </>
        )}
      </nav>
      <div className={`${burgerOpen ? clsx.open_menu : clsx.menu}`}>
        <ul className="container">
          {isAuth ? (
            <>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/");
                }}
              >
                Home page
              </li>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/how_it_works");
                }}
              >
                How it works
              </li>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/browse");
                }}
              >
                Browse apartments
              </li>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/blog");
                }}
              >
                Blog
              </li>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/account/profile");
                }}
              >
                My account
              </li>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/add_apartmants");
                }}
              >
                Add an advert
              </li>
            </>
          ) : (
            <>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/");
                }}
              >
                Home page
              </li>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/how_it_works");
                }}
              >
                How it works
              </li>
              <li
                onClick={() => {
                  handleClick();
                  openModal();
                }}
              >
                Browse apartments
              </li>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/blog");
                }}
              >
                Blog
              </li>
              <li
                onClick={() => {
                  handleClick();
                  openModal();
                }}
              >
                Log in
              </li>
              <li
                onClick={() => {
                  handleClick();
                  navigate("/registration");
                }}
              >
                Join now
              </li>
            </>
          )}
        </ul>
      </div>
      <ModalComponent>
        <Login isOpen={isOpen} openModal={openModal} closeModal={closeModal} />
      </ModalComponent>
    </>
  );
};

export default Navbar;
