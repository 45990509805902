import { FC } from "react";
import { StepProps } from "./model/types";
import styles from "./ui/index.module.css";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import SocialMediaInputs from "../../../shared/socialMediaGroup/SocialMedia";
import { useSearchParams } from "react-router-dom";

const SocialMedia: FC<StepProps> = ({ formData, setFormData }) => {
  const [searchParams] = useSearchParams();
  const payment = searchParams.get("payment");
  return (
    <div className={`${styles.step2_wrapper} ${styles.social_wrapper}`}>
      <div className={styles.step1_titles}>
        <SecondTitle className={styles.social_title}>
          You have activated access!
        </SecondTitle>
        <Text>
          You will receive information about joining the community within 20
          minutes.{" "}
          <strong style={{ fontWeight: "500" }}>
            {payment === "tg"
              ? "Please, confirm your account in telegram."
              : ""}
          </strong>
        </Text>
        <SecondTitle>Share your username on Instagram or LinkedIn</SecondTitle>
        <Text>
          Social media verification gives you a +70% chance of approval!
        </Text>
        {setFormData ? (
          <SocialMediaInputs
            formData={formData}
            setFormData={setFormData}
            socialMediaArray={["instagram", "linkedin"]}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SocialMedia;
