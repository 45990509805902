import { FC, useEffect } from "react";
import styles from "./styles.module.css";
import { useSearchParams } from "react-router-dom";

interface Props {
  length?: any;
}

const Pagination: FC<Props> = ({ length }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || "1");
  console.log(length);
  const totalPages = Math.ceil((length || 1000) / 10);
  const maxButtons = length || 3;
  console.log(length, totalPages);
  const startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
  const endPage = Math.min(totalPages, startPage + maxButtons - 1);

  const setCurrentPage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <button
        className={styles.pagination_btn}
        key={i}
        onClick={() => handlePageChange(i)}
        disabled={i === currentPage}
      >
        {i}
      </button>
    );
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);

  return (
    <div className={styles.pagination}>
      {currentPage > 1 && (
        <button
          className={styles.prev_btn}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Succeeding
        </button>
      )}
      {currentPage > 3 && (
        <>
          <button
            className={styles.pagination_btn}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
          {startPage > 2 && <span className={styles.ellipsis}>...</span>}
        </>
      )}
      {pages}
      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && (
            <span className={styles.ellipsis}>...</span>
          )}
          <button
            className={styles.pagination_btn}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </>
      )}
      {currentPage < totalPages && (
        <button
          className={styles.further_btn}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Further
        </button>
      )}
    </div>
  );
};

export default Pagination;
