import clsx from "./ui/indx.module.css";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import { Text } from "../../shared/Text/Text";
import Uploader from "../../entities/uploader/Uploader";
import { Input } from "../../shared/input/Input";
import Rooms from "../../shared/rooms/Rooms";
import DatePicker from "react-datepicker";
import { useEffect, useState, useCallback } from "react";
import { TextArea } from "../../shared/textArea/TextArea";
import Amenities from "../../shared/amenities/Amenities";
import { Button } from "../../shared/button/Button";
import {
  useAddFlatMutation,
  useDeleteAdvertMutation,
  useUploadImagesMutation,
} from "../../app/redux/product/apiProducts";
import { useNavigate } from "react-router-dom";
import AddAdvertPopUp from "../../entities/addAdvertPopUp/addAdvertPopUp";
import { useModal } from "../../app/helpers/hooks/useModal";
import { useGetUserQuery } from "../../app/redux/auth/authApi";

const AddFlat = () => {
  const [formData, setFormData] = useState({
    departure: null as Date | null,
    returnDate: null as Date | null,
    images: [] as File[],
    rooms: null as number | null,
    description: "",
    address: "",
    price: "",
    comments: "",
    country: "",
    city: "",
  });

  const navigate = useNavigate();

  const { isOpen, openModal, closeModal, ModalComponent } = useModal();

  const [deleteAdvert] = useDeleteAdvertMutation();
  const [addFlat] = useAddFlatMutation();
  const [uploadImage] = useUploadImagesMutation();
  const { data } = useGetUserQuery({});

  const socialNetworks = {
    Instagram: data?.instagram,
    LinkedIn: data?.linkedin,
    WhatsApp: data?.whatsapp,
    Telegram: data?.tg_username,
  };

  const handleInputChange = (field: string, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const archiveItem = async (item: any) => {
    await deleteAdvert({ listing_id: item?.listing_id });
    navigate("/account/myarchivedads");
    window.location.reload();
  };

  const handleSave = async (archive = false) => {
    const data = {
      description: formData.description || "",
      date_from: formData.departure ? formData.departure.toISOString() : "",
      date_to: formData.returnDate ? formData.returnDate.toISOString() : "",
      country: formData.country || "",
      city: formData.city || "",
      price: formData.price || "",
      comments: formData.comments || "",
      district: formData.address || "",
      room: formData.rooms ? formData.rooms.toString() : "",
    };

    try {
      const result = await addFlat(data).unwrap();
      if (result.listing_id) {
        const formDataObj = new FormData();
        formData.images.forEach((file: File) => {
          formDataObj.append("files", file);
        });

        await uploadImage({ data: formDataObj, id: result.listing_id });
        navigate("/account/myprofile");
      }
      if (archive && result) {
        archiveItem(result);
      }
    } catch (error) {
      console.error("Failed to save the advertisement:", error);
    }
  };

  const handleSaveClick = () => {
    const hasSocialNetworks = Object.values(socialNetworks).some(
      (value) => !!value
    );

    if (hasSocialNetworks) {
      handleSave();
    } else {
      openModal();
    }
  };

  return (
    <div className={`${clsx.wrapper} container`}>
      <SecondTitle
        className={clsx.title}
        onClick={() => navigate("/add_apartmants")}
      >
        Add an advert
      </SecondTitle>
      <div className={clsx.upload}>
        <SecondTitle>Photos of the apartment</SecondTitle>
        <div className={clsx.upload_item}>
          <Text>Upload no more than 8 photos of your apartment.</Text>
          <Uploader
            required
            onImageChanger={(data) => handleInputChange("images", data)}
          />
        </div>
      </div>
      <div className={clsx.location}>
        <SecondTitle>Location</SecondTitle>
        <div>
          <Input
            required
            placeholder="Enter the country"
            value={formData.country}
            onChange={(e) => handleInputChange("country", e.target.value)}
          />
          <Input
            required
            placeholder="Enter the city"
            value={formData.city}
            onChange={(e) => handleInputChange("city", e.target.value)}
          />
          <Input
            required
            placeholder="Enter the district"
            value={formData.address}
            onChange={(e) => handleInputChange("address", e.target.value)}
          />
        </div>
      </div>
      <div className={clsx.rooms}>
        <SecondTitle>Number of rooms</SecondTitle>
        <div>
          <Rooms handlegetRooms={(data) => handleInputChange("rooms", data)} />
        </div>
      </div>
      <div className={clsx.time}>
        <SecondTitle>Departure date</SecondTitle>
        <div>
          <DatePicker
            required
            selected={formData.departure}
            onChange={(date) => handleInputChange("departure", date)}
            placeholderText="Select a date"
            className={clsx.date_picker}
          />
        </div>
      </div>
      <div className={clsx.time}>
        <SecondTitle>Return to the apartment</SecondTitle>
        <div>
          <DatePicker
            required
            selected={formData.returnDate}
            onChange={(date) => handleInputChange("returnDate", date)}
            placeholderText="Select a date"
            className={clsx.date_picker}
          />
        </div>
      </div>
      <div className={clsx.description}>
        <SecondTitle>Description of the apartment</SecondTitle>
        <div>
          <TextArea
            required
            placeholder="Enter a description of the apartment"
            value={formData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
        </div>
      </div>
      <div>
        {/*<SecondTitle>Amenities</SecondTitle>
        <div>
          <Amenities />
        </div>*/}
      </div>
      <div className={clsx.cost}>
        <SecondTitle>Cost</SecondTitle>
        <Text>
          For comfortable use of the community for all participants, the rent
          should be indicated at the cost of a long-term lease. Learn more
        </Text>
        <div>
          <Input
            required
            placeholder="$/night"
            value={formData.price}
            onChange={(e) => handleInputChange("price", e.target.value)}
          />
        </div>
      </div>
      <div className={clsx.comment}>
        <SecondTitle>Comment</SecondTitle>
        <Text>
          If you have any suggestions or comments about the ad, please add them.
        </Text>
        <div>
          <TextArea
            placeholder="Enter the comment"
            value={formData.comments}
            onChange={(e) => handleInputChange("comments", e.target.value)}
          />
        </div>
      </div>
      <Button
        onClick={handleSaveClick}
        style={{ alignSelf: "flex-start" }}
        $bg
        $icon
      >
        Save
      </Button>

      <ModalComponent>
        <AddAdvertPopUp
          closeModal={closeModal}
          openModal={openModal}
          isOpen={isOpen}
          handleSave={handleSave}
        />
      </ModalComponent>
    </div>
  );
};

export default AddFlat;
