import { useNavigate, useParams } from "react-router-dom";
import styles from "./ui/index.module.css";
import { Logo } from "../../shared/logo/Logo";
import img from "../../assets/png/auth1.png";
import { Button } from "../../shared/button/Button";
import { Input } from "../../shared/input/Input";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import { Text } from "../../shared/Text/Text";
import { ChangeEvent, useState } from "react";
import { useResetPasswordMutation } from "../../app/redux/auth/authApi";

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [resetPassword] = useResetPasswordMutation();

  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });
  console.log(formData);
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : prevErrors[name],
    }));
  };

  const handleSubmit = async () => {
    const newErrors: { [key: string]: string } = {};
    if (formData.password !== formData.confirm_password) {
      newErrors.error = "Passwords must match";
      setErrors(newErrors);
      return;
    }
    try {
      const { data: resetResult, error: resetError } = await resetPassword({
        data: { token: code, password: formData.password },
      });
      if (resetResult == null) {
        navigate("/");
      }
      if (resetError) {
        newErrors.error = "Something went wrong, check the passwords";
        setErrors(newErrors);
      }
    } catch (error) {}
  };

  return (
    <div className={`${styles.wrapper} container`}>
      <div className={styles.left_block}>
        <Logo className={styles.left_block_logo} onClick={() => navigate("/")}>
          <span>flat</span>
          <span>sharing</span>
        </Logo>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className={styles.content_block}
        >
          <SecondTitle>Restoring access</SecondTitle>
          <Text>
            Come up with a strong password consisting of letters, numbers
            and other symbols.
          </Text>
          <div className={styles.inputs}>
            <Input
              required
              placeholder="New password"
              name="password"
              type={checked ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
            />
            <Input
              $error={errors?.error ? true : false}
              required
              placeholder="Confirm new password"
              type={checked ? "text" : "password"}
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
            />
            {errors?.error && (
              <Text style={{ color: "red", fontSize: "14px" }}>
                {errors.error}
              </Text>
            )}
            <div className={styles.check_block}>
              <div className={styles.check}>
                <input
                  onChange={() => setChecked(!checked)}
                  type="checkbox"
                  name=""
                  id="chekc"
                />
                <label htmlFor="chekc">Show password</label>
              </div>
            </div>
          </div>

          <Button type="submit" $bg $icon>
            Save
          </Button>
        </form>
      </div>
      <img className={styles.images} src={img} alt="" />
    </div>
  );
};

export default PasswordRecovery;
