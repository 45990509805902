import clsx from "./ui/index.module.css";

import accanture from "../../../assets/png/accanture.png";
import amazon from "../../../assets/png/amazon.png";
import booking from "../../../assets/png/booking.png";
import meta from "../../../assets/png/meta.png";
import google from "../../../assets/png/google.png";
import jet from "../../../assets/png/jet.png";

const images = [meta, booking, accanture, amazon, google, jet];

const CommunityWidgets = () => {
  return (
    <div className={clsx.bg}>
      <div className="items-wrap">
        <div className="items marquee">
          {images.map((item) => (
            <div className="item">
              <img src={item} alt="" />
            </div>
          ))}
        </div>
        <div aria-hidden="true" className="items marquee">
          {images.map((item) => (
            <div className="item">
              <img src={item} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommunityWidgets;
