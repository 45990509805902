import { FC } from "react";
import { Button } from "../../shared/button/Button";
import { Input } from "../../shared/input/Input";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";

import styles from "./ui/index.module.css";
import closeIcon from "../../assets/icons/close.svg";
import { ModalProps } from "./model/types";
import { useNavigate } from "react-router-dom";

const Payment: FC<ModalProps> = ({ closeModal }) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.content_wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      {/*{errors && errors.error && (
        <div className={styles.errors}>
          <div>{errors.error}</div>
        </div>
      )}*/}
      <div className={styles.content}>
        <div
          className={styles.close_icon}
          onClick={() => {
            closeModal();
          }}
        >
          <img src={closeIcon} alt="close icon" />
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            closeModal();
            navigate("/registration?step=9&payment=tg");
          }}
          className={styles.content_block}
        >
          <SecondTitle mb="20px">TG Username</SecondTitle>

          <Input name="username" placeholder="Enter your telegram username" />
          <Button style={{ margin: "20px 0px" }} type="submit" $bg $icon>
            Enter
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Payment;
