import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const token = localStorage.getItem("token");

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FLAT_API,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    listing: builder.query({
      query: ({
        offset,
        direction,
        check_in_date,
        check_out_date,
        sorting,
      }) => {
        console.log(offset);
        if (offset && direction && check_in_date && check_out_date && sorting) {
          return `/listings?direction=${direction}&check_in_date=${check_in_date}&offset=${offset}&check_out_date=${check_out_date}&sorting=${sorting}&offset=${offset}&limit=10`;
        } else {
          return `/listings?offset=${offset}&limit=10`;
        }
      },
    }),
    getOneFlat: builder.query({
      query: ({ id }) => `/listings/${id}`,
    }),
    getUsersProducts: builder.query({
      query: ({ statuses }) => {
        const queryString = statuses
          ? statuses.map((status: string) => `statuses=${status}`).join("&")
          : "";

        return `/listings/user/me?limit=10000&${queryString}`;
      },
    }),
    addFlat: builder.mutation({
      query: (data: unknown) => ({
        url: "/listings",
        method: "POST",
        body: data,
      }),
    }),
    uploadImages: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/listings/${id}/pictures`,
          method: "POST",
          body: data,
        };
      },
    }),
    editAdvert: builder.mutation({
      query: ({ listing_id, data }) => {
        return {
          url: `/listings/me/${listing_id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    deleteAdvert: builder.mutation({
      query: ({ listing_id }) => {
        return {
          url: `/listings/${listing_id}`,
          method: "DELETE",
        };
      },
    }),
    listingPictureUpdate: builder.mutation({
      query: ({ listing_picture_id, images }) => {
        return {
          url: `/listing_pictures/${listing_picture_id}`,
          method: "PATCH",
          body: images,
        };
      },
    }),

    getUserInfo: builder.query({
      query: () => "/users/me",
    }),
  }),
});

export const {
  useListingQuery,
  useGetOneFlatQuery,
  useAddFlatMutation,
  useGetUsersProductsQuery,
  useUploadImagesMutation,
  useEditAdvertMutation,
  useDeleteAdvertMutation,
  useListingPictureUpdateMutation,
  useGetUserInfoQuery,
} = productsApi;
