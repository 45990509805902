import styles from "./ui/ui.module.css";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, FC, useState, useEffect } from "react";

import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import { Input } from "../../../shared/input/Input";
import { Button } from "../../../shared/button/Button";
import { Linked } from "../../../shared/Linked/Linked";

import {
  useForgotPasswordMutation,
  useLoginMutation,
} from "../../../app/redux/auth/authApi";

import closeIcon from "../../../assets/icons/close.svg";
import {
  FormDataTypes,
  ModalProps,
  Props,
  ResetPasswordProps,
} from "./model/types";
import { emailRegEx } from "../../../app/constants/regex";

const AcceptedLogIn: FC<Props> = ({ getUserInfo }) => {
  const user = localStorage.getItem("token");

  const fetchUserInfo = async () => {
    if (user) {
      const data = await fetch(
        "https://api.flatsharingcommunity.com/users/me",
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user}`,
          },
        }
      ).then((data) => data.json());
      getUserInfo(data);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <>
      <div className={styles.content_block}>
        <SecondTitle fz="40px" mb="40px">
          Your application to join the community is being processed
        </SecondTitle>
        <Text>
          You will receive information about joining the community
          within 20 minutes after filling out the questionnaire.
        </Text>
      </div>
      <br />
    </>
  );
};

const ResetPasswordDone: FC<ResetPasswordProps> = ({ email }) => {
  return (
    <>
      <div className={styles.content_block}>
        <SecondTitle fz="40px" mb="40px">
          Done! To restore your account, click on the link in the e-mail
        </SecondTitle>
        <Text>The letter went to {email}</Text>
      </div>
      <br />
    </>
  );
};

const Login: FC<ModalProps> = ({
  closeModal: closeLoginModal,
  isForgot = false,
}) => {
  const navigate = useNavigate();
  const user = localStorage.getItem("token");

  const [formData, setFormData] = useState<FormDataTypes>({
    email: "",
    password: "",
  });
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [checked, setChecked] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isForgotPassword, setIsForgotPassword] = useState(isForgot);

  const [login] = useLoginMutation();
  const [resetPassword] = useForgotPasswordMutation();

  const getUserInfo = (data: any) => {
    setUserInfo(data);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : prevErrors[name],
    }));
  };

  const handleResetPassword = async () => {
    const newErrors: { [key: string]: string } = {};
    if (!emailRegEx.test(forgotPasswordEmail)) {
      newErrors.error = "Please enter a valid email address";
      setErrors(newErrors);
      return;
    }
    try {
      const { data: resetPasswordResult } = await resetPassword({
        data: {
          email: forgotPasswordEmail,
        },
      });
      if (resetPasswordResult == null) {
        setShowSuccessMessage(true);
      }
    } catch (error) {
      newErrors.error =
        "The user was not found. Check the entered e-mail address and try again.";
      setErrors(newErrors);
    }
  };

  const handleLogin = async () => {
    const loginData = new FormData();
    loginData.append("username", formData.email);
    loginData.append("password", formData.password);
    const newErrors: { [key: string]: string } = {};

    try {
      const { data: loginResult, error: loginError } = await login({
        user: loginData,
      });
      console.log(loginError);
      if (loginResult) {
        localStorage.setItem("token", loginResult.access_token);
        setShowSuccessMessage(true);
      }
      if (loginError) {
        newErrors.error = "Invalid email or password.";
        setErrors(newErrors);
      }
    } catch (error) {
      newErrors.error = "Invalid email or password.";
      setErrors(newErrors);
    }
  };

  console.log(userInfo);
  useEffect(() => {
    if (userInfo?.is_accepted) {
      console.log("worked");
      setShowSuccessMessage(false);
      closeLoginModal();
      window.location.reload();
    }
  }, [userInfo?.is_accepted]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleLogin();
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleLogin, handleResetPassword]);

  return (
    <div
      className={styles.content_wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      {errors && errors.error && (
        <div className={styles.errors}>
          <div>{errors.error}</div>
        </div>
      )}
      <div className={styles.content}>
        <div
          className={styles.close_icon}
          onClick={() => {
            closeLoginModal();
          }}
        >
          <img src={closeIcon} alt="close icon" />
        </div>

        {showSuccessMessage ? (
          <>
            {isForgotPassword ? (
              <ResetPasswordDone email={forgotPasswordEmail} />
            ) : (
              <>{user && <AcceptedLogIn getUserInfo={getUserInfo} />}</>
            )}
          </>
        ) : (
          <>
            {isForgotPassword ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleResetPassword();
                }}
                className={styles.content_block}
              >
                <SecondTitle mb="20px">Forgot your password?</SecondTitle>
                <Text style={{ marginBottom: "30px" }}>
                  Enter the email address associated with your account and we’ll
                  send you a link to reset your password.
                </Text>
                <div className={styles.inputs}>
                  <Input
                    $error={errors?.email ? true : false}
                    required
                    placeholder="E-mail"
                    type="email"
                    name="email"
                    value={forgotPasswordEmail}
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  />
                </div>

                <Button style={{ marginTop: "30px" }} type="submit" $bg $icon>
                  Continue
                </Button>
              </form>
            ) : (
              <>
                <form id="loginForm" className={styles.content_block}>
                  <SecondTitle mb="40px">Log in</SecondTitle>
                  <div className={styles.inputs}>
                    <Input
                      $error={errors?.email ? true : false}
                      required
                      placeholder="E-mail"
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <Input
                      $error={errors?.password ? true : false}
                      required
                      placeholder="Password"
                      type={checked ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <div className={styles.check_block}>
                      <div className={styles.check}>
                        <input
                          onChange={() => setChecked(!checked)}
                          type="checkbox"
                          name=""
                          id="chekc"
                        />
                        <label htmlFor="chekc">Show password</label>
                      </div>
                      <Text
                        fw="500"
                        onClick={() => {
                          setIsForgotPassword(true);
                        }}
                      >
                        Forgot your password?
                      </Text>
                    </div>
                  </div>

                  <Button type="button" onClick={handleLogin} $bg $icon>
                    Enter
                  </Button>
                </form>
                <div className={styles.link}>
                  <Text fw="500">Don't have an account? </Text>
                  <Linked
                    onClick={() => {
                      closeLoginModal();
                      navigate("/registration");
                    }}
                    $weight="500"
                    $underline
                  >
                    Join Now
                  </Linked>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
