import { DragEvent, ChangeEvent, FC } from "react";
import clsx from "./ui/index.module.css";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import { StepProps } from "./model/types";

const ImageUploader: FC<StepProps> = ({
  handleChange,
  handleDrop,
  name,
  imageFile,
}) => {
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
  };

  return (
    <div>
      <div className={clsx.uploader_titles}>
        <SecondTitle>Upload your photo</SecondTitle>
      </div>
      {imageFile ? (
        <img
          className={clsx.immages_uploader}
          src={URL.createObjectURL(imageFile)}
          alt="Uploaded Preview"
        />
      ) : (
        <div
          className={clsx.uploader}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          Upload or drag a photo
          <input
            type="file"
            accept="image/*"
            onChange={handleChange}
            name={name}
            style={{ display: "none" }}
            id="fileInput"
          />
        </div>
      )}
      <div className={clsx.check} style={{ cursor: "pointer" }}>
        <input type="checkbox" id="checked" />
        <label htmlFor="checked">
          I accept Terms of Service and Privacy Policy
        </label>
      </div>
    </div>
  );
};

export default ImageUploader;
