import styles from "../ui/index.module.css";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import {
  useGetUserQuery,
  useLogoutMutation,
} from "../../../app/redux/auth/authApi";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../shared/button/Button";

const Sidebar = () => {
  const { data } = useGetUserQuery({});
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout("");
      localStorage.removeItem("token");
      navigate("/");
      window.location.reload();
    } catch (error) {}
  };
  return (
    <div
      className={`${styles.left_side} ${
        location.pathname == "/account/myads" ||
        location.pathname == "/account/myarchivedads" ||
        location.pathname == "/account/myprofile" ||
        location.pathname == "/account/subscription"
          ? styles.hide_list
          : null
      } `}
    >
      <div className={styles.user_block}>
        <div className={styles.user_avatar}>
          <img
            src={
              data?.picture_url
                ? data.picture_url
                : "https://i.pinimg.com/564x/25/ee/de/25eedef494e9b4ce02b14990c9b5db2d.jpg"
            }
            alt="user avatar"
          />
          <span onClick={() => navigate("/account/myprofile")}>...</span>
        </div>
        <SecondTitle weight="400" fz="20px" style={{ minHeight: "24px" }}>
          {data?.full_name || ""}
        </SecondTitle>
      </div>
      <ul className={styles.list}>
        <li
          onClick={() => navigate("/account/myads")}
          className={
            location.pathname == "/account/myads" ||
            location.pathname == "/account/profile"
              ? styles.active_li
              : location.pathname == "/account/myarchivedads"
              ? styles.active_li
              : styles.inactive_li
          }
        >
          My Ads
        </li>
        <li
          onClick={() => navigate("/account/myprofile")}
          className={
            location.pathname == "/account/myprofile"
              ? styles.active_li
              : styles.inactive_li
          }
        >
          My Profile
        </li>
        <li
          onClick={() => navigate("/account/subscription")}
          className={
            location.pathname == "/account/subscription"
              ? styles.active_li
              : styles.inactive_li
          }
        >
          Subscription
        </li>
      </ul>
      <Button
        style={{
          paddingLeft: "0px",
          justifyContent: "left",
        }}
        $icon
        $iconBgColor="#F5F6F6"
        className={styles.logout_btn}
        onClick={handleLogout}
      >
        Log out of profile
      </Button>
    </div>
  );
};

export default Sidebar;
